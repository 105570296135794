import React, { useEffect, useRef, useState, useContext } from "react";
import { GlobalContext } from "../Globalstate";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import Nav from "../Components/Nav";
import Sidebar from "../Components/Sidebar";
import DepositComplete from "./DepositComplete";
import Swal from "sweetalert2";
import { f, database, storage, auth } from "../config";
import db from "../config";
import firebase from "firebase";
import emailjs from "emailjs-com";

function Subscribe() {
  const [{ userdetails, loggedin, tradingpair }, dispatch] =
    useContext(GlobalContext);
  const navigate = useNavigate();
  const [loading, setloading] = useState(true);
  const [amount, setamount] = useState("");

  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setloading(false);
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
        } else {
          setloggedin(false);
          setloading(false);
          navigate("/");
        }
      });
    }

    let myDate = new Date();
    console.log(myDate.getTime());
    console.log(addHoursToDate(myDate, 1).getTime());
    console.log(myDate);
    console.log(addHoursToDate(myDate, 1));
  }, []);

  function addHoursToDate(date, hours) {
    return new Date(new Date(date).setHours(date.getHours() + hours));
  }

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    const fetching = await docRef.onSnapshot(function (doc) {
      if (doc.exists) {
        setdetails(doc.data());
        console.log(doc.data());
        setloading(false);
      } else {
        console.log("No such document!");
      }
    });
  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };

  const investments = [
    {
      id: "1",
      name: "Rudiments Plan",
      minimum: "100",
      maximum: "499",
      Maximum:"499",
      profits: "3",
      duration: "31",
      description: "Capital returns on maturity, 24/7 support",
    },
    {
      id: "2",
      name: "Gold Plan",
      minimum: "500",
      maximum: "999",
      Maximum:"999",
      profits: "3.9",
      duration: "31",
      description: "Capital returns on maturity, 24/7 support",
    },
    {
      id: "3",
      name: "Antminer Plan",
      minimum: "1000",
      maximum: "2999",
      Maximum:"2999",
      profits: "4",
      duration: "30",
      description: "Capital returns on maturity, 24/7 support",
    },
    {
      id: "4",
      name: "Antminer Pro Plan",
      minimum: "3000",
      maximum: "4999",
      Maximum:"4999",
      profits: "4.5",
      duration: "30",
      description: "Capital returns on maturity, 24/7 support",
    },
    {
      id: "5",
      name: "Innosilicon Plan",
      minimum: "5000",
      maximum: "7999",
      Maximum:"7999",
      profits: "5",
      duration: "30",
      description: "Capital returns on maturity, 24/7 support",
    },
    {
      id: "6",
      name: "Variable Plan",
      minimum: "8000",
      maximum: "11999",
      Maximum:"11999",
      profits: "8",
      duration: "30",
      description: "Capital returns on maturity, 24/7 support",
    },
    {
      id: "7",
      name: "Variable Pro Plan",
      minimum: "12000",
      maximum: "14999",
      Maximum:"14999",
      profits: "8.9",
      duration: "30",
      description: "Capital returns on maturity, 24/7 support",
    },
    {
      id: "8",
      name: "VIP Plan",
      minimum: "15000",
      Maximum: "100000000",
      maximum: "unlimited",
      profits: "10",
      duration: "25",
      description: "Capital returns on maturity, 24/7 support",
    },
    {
      id: "9",
      name: "VIP Pro Plan",
      minimum: "22000",
      Maximum: "100000000",
      maximum: "unlimited",
      profits: "12.5",
      duration: "25",
      description: "Capital returns on maturity, 24/7 support",
    },
    {
      id: "10",
      name: "Shareholder Plan",
      minimum: "100000",
      Maximum: "100000000",
      maximum: "unlimited",
      profits: "30",
      duration: "35",
      description: "Capital returns on maturity, 24/7 support",
    },
    {
      id: "11",
      name: "Shareholder Pro Plan",
      minimum: "200000",
      Maximum: "100000000",
      maximum: "unlimited",
      profits: "50",
      duration: "45",
      description: "Capital returns on maturity, 24/7 support",
    },
  ];
  const reffralCheck = () => {
    if (userdetails.referreduserid == "nnnnn") {
      console.log("np reffreal");
    } else {
      //increament referreduserid balance with 5% of invested amount
      var washingtonRef = db
        .collection("users")
        .doc(userdetails.referreduserid);
      const increment = firebase.firestore.FieldValue.increment(
        (5 * parseInt(amount)) / 100
      );
      washingtonRef
        .update({
          balance: increment,
        })
        .then(function () {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          var washingtonRef2 = db.collection("users").doc(userids);
          // set referreduserid to "nnnnn"
          washingtonRef2.update({
            referreduserid: "nnnnn",
          });
        })
        .catch(function (error) {
          console.log("Error updating balance");
        });
    }
  };

  const planactivation = (value) => {
    let SelectedInvestment = investments.find((o) => o.id === value);
    // console.log(obj)
    if (amount !== "") {
      // if (userdetails.currentSubscription == null) {

      Swal.fire({
        title: "Are you sure?",
        text: `Do you relly want to invest in ${SelectedInvestment.name}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, continue",
      }).then((result) => {
        if (result.isConfirmed) {
          if (
            parseInt(userdetails.balance) !== 0 &&
            parseInt(amount) <= parseInt(userdetails.balance)
          ) {
            if (
              parseInt(amount) >= parseFloat(SelectedInvestment.minimum) &&
              parseInt(amount) <= parseFloat(SelectedInvestment.Maximum)
            ) {
              let amount1 = parseInt(amount);
              // if (value == "1" && amount1 >= 300 && amount1 <= 10000) {
              let bal = parseInt(userdetails.balance);
              const newbal = bal - amount1;
              // 92 no of days 1.8 is percent
              let due =
                parseFloat(SelectedInvestment.duration) *
                  ((parseFloat(SelectedInvestment.profits) / 100) * amount1) +
                amount1;
              let myDate = new Date();
              // multiply number of days with 24
              const d2 = addHoursToDate(
                myDate,
                parseFloat(SelectedInvestment.duration) * 24
              ).getTime();
              var cap = new Date(d2);
              console.log(cap.toString());
              const subscription = {
                amount: amount1,
                currentPlan: SelectedInvestment.name,
                dueAmount: due,
                dueDate: d2,
                dateSubscribed: new Date().getTime(),
              };
              updateUserBalanceAndSub(
                newbal,
                subscription,
                SelectedInvestment.name,
                due
              );
              reffralCheck();
              Swal.fire(
                "Succesful Transaction!",
                "Your Investment has been activated.",
                "success"
              );
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "You selected the wrong investment for selected amount",
                footer: '<a href="">Why do I have this issue?</a>',
              });
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "you dont have enough balance proceed to funding section to credit your account!",
              footer: '<a href="">Why do I have this issue?</a>',
            });
            //   handleClick();
            //show toast
          }
        }
      });

      // } else {
      //     Swal.fire({
      //         icon: 'error',
      //         title: 'Oops...',
      //         text: 'You Have Plan Running Currently!',
      //     })

      // }
      //check this
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "fill in the requied fields!",
        footer: '<a href="">Why do I have this issue?</a>',
      });
      //show toast
    }
  };

  const CreatUserObj = async (obj, subsription) => {
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    let uobj = {
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      fullname: userdetails.fullname,
      email: userdetails.email,
      userid: userids,
      info: obj,
      subsription: subsription,
    };
    const userdetail1 = await db
      .collection("investments")
      .add(uobj)
      .then(function () {
        console.log("Document successfully written!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };

  const updateUserBalanceAndSub = (bal, subsription, plans, intrest) => {
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    var washingtonRef = db.collection("users").doc(userids);
    const increment = firebase.firestore.FieldValue.increment(intrest);
    washingtonRef
      .update({
        balance: bal,
        currentSubscription: subsription,
        // totalearnings: increment,
        currentSubscriptionArray:
          firebase.firestore.FieldValue.arrayUnion(subsription),
        Investments: firebase.firestore.FieldValue.arrayUnion({
          date: Date.now(),
          plan: plans,
          amount: amount,
        }),
      })
      .then(function () {
        console.log("Document successfully updated!");
        CreatUserObj(
          {
            date: Date.now(),
            plan: plans,
            amount: amount,
          },
          subsription
        );
      })
      .catch(function (error) {
        console.error("Error updating document: ", error);
      });
  };

  return (
    <div>
      {loading && (
        <div class="preloader js-preloader">
          <div class="loader loader-inner-1">
            <div class="loader loader-inner-2">
              <div class="loader loader-inner-3"></div>
            </div>
          </div>
        </div>
      )}
      <div id="app">
        {/*/PayPal*/}
        {/*Start of Tawk.to Script*/}
        {/*End of Tawk.to Script*/}
        <div className="wrapper">
          <div className="main-header">
            <div id="google_translate_element"></div>
            {/* <ScriptTag type="text/javascript">
                                {`

                                  function googleTranslateElementInit() {
                                  new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element');
                                    }
                                     `}
                            </ScriptTag>
                            <ScriptTag
                                type="text/javascript"
                                src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
                            ></ScriptTag> */}
            <Nav />
            {/* End Navbar */}
          </div>
          {/* Stored in resources/views/child.blade.php */}
          {/* Sidebar */}
          <Sidebar />
          {/* End Sidebar */}
          <div className="main-panel bg-light">
            <div className="content bg-light">
              <div className="page-inner">
                <div className="mt-2 mb-4">
                  <h1 className="title1 text-dark">Available packages</h1>
                </div>
                <div></div> <div></div>
                <section class="section">
                  <div class="container">
                    <div class="row align-items-stretch">
                    {investments.map((plan) => (
                        <div key={plan.id} className="col-lg-4 mb-4 mb-lg-0">
                          <div className="pricing h-100 text-center">
                            <h3>{plan.name}</h3>
                            <ul className="list-unstyled">
                              <li>
                                Minimum: ${plan.minimum} / ${plan.maximum}
                              </li>
                              <li>Daily Percentage Profit: {plan.profits}%</li>
                              <li>
                                CAPITAL RETURNS AFTER MATURITY {plan.duration}{" "}
                                days
                              </li>
                              <li>
                                <form>
                                  <h5 className="text-dark">
                                    Insert Amount to invest
                                  </h5>
                                  <input
                                    type="number"
                                    min={plan.minimum}
                                    max={plan.maximum}
                                    // value={amount[plan.id] || ""}
                                    onChange={(e) => {
                                      setamount(e.target.value);
                                    }}
                                    placeholder={`$${plan.minimum}`}
                                    className="form-control text-dark bg-light"
                                  />
                                  <br />
                                  <input
                                    type="hidden"
                                    name="duration"
                                    value={`${plan.duration} Days`}
                                  />
                                  <input
                                    type="hidden"
                                    name="id"
                                    value={plan.id}
                                  />
                                  <input
                                    type="hidden"
                                    name="_token"
                                    value="zn8v4I2d4bZyK4wpUA2IxCApAntbf4ODlmrnGzIz"
                                  />
                                  <input
                                    className="btn btn-block pricing-action btn-primary"
                                    value="Join plan"
                                    type="button"
                                    onClick={() => planactivation(plan.id)}
                                  />
                                </form>
                              </li>
                            </ul>
                            <div className="price-cta">
                              <strong className="price">
                                {plan.profits}% Daily Profit
                              </strong>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <footer className="footer bg-light text-dark">
              <div className="container-fluid">
                <div className="text-center row copyright text-align-center">
                  <p>All Rights Reserved © Financetellar 2022</p>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
      {/*   Core JS Files   */}
      {/* jQuery UI */}
      {/* jQuery Scrollbar */}
      {/* jQuery Sparkline */}
      {/* Sweet Alert */}
      {/* Bootstrap Notify */}
    </div>
  );
}

export default Subscribe;
