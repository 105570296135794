import firebase from 'firebase';
const firebaseConfig = {
  apiKey: "AIzaSyCnTi35DaQNphiPSF5BPHi4bkgczddIclk",
  authDomain: "financetellar.firebaseapp.com",
  databaseURL: "https://financetellar-default-rtdb.firebaseio.com",
  projectId: "financetellar",
  storageBucket: "financetellar.appspot.com",
  messagingSenderId: "386503524701",
  appId: "1:386503524701:web:3ce7b365f0be38942468b4"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.firestore().settings({ timestampsInSnapshots: true })

export const f = firebase;
export const database = firebase.database();
export const storage = firebase.storage();
export const auth = firebase.auth();
export default firebase.firestore()